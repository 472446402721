import { useState } from 'react';
import { useDispatch } from 'react-redux';
import FormInput from '../form-input/form-input.component';
import Button from '../button/button.component';

import { signUpStart } from '../../store/user/user.action';

import './sign-up-form.styles.scss'

const defaultFormFields = {
    displayName: '',
    email: '',
    password: '',
    confirmPassword: ''
}

const SignUpForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields)
    const { displayName, email, password, confirmPassword } = formFields;
    const dispatch = useDispatch()


    console.log('hit')

    const resetFormFields = () => {
        setFormFields(defaultFormFields);

    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert("passwords do not match");
            return;
        }

        try {
          dispatch(signUpStart(email,password, displayName))
            resetFormFields();

        } catch(error) {
            if (error.code === 'auth/email-already-in-use') {
                alert('email already in use')
            } else {
              console.log('user encountered error',error)
            }
            

          }
        };

        

    const handleChange = (event) => {
        const {name, value } = event.target;
        
        setFormFields({...formFields, [name]: value})

    };

    return (
        <div className='sign-up-container'>
        <h2>Dont have an account?</h2>
          <span>Sign up with email an password</span>
          <form onSubmit={handleSubmit}>
          <FormInput
            label = 'Display Name'
            type='text'
            required
            onChange={handleChange}
            name='displayName'
            value={displayName}
          />
          <FormInput
            label="email"
            type='email'
            required
            onChange={handleChange}
            name='email'
            value={email}
            />

          <FormInput
            label="password"
            type='password'
            required
            onChange={handleChange}
            name='password'
            value={password} />

          <FormInput
            label='Confirm Password'
            type='password'
            required
            onChange={handleChange}
            name='confirmPassword'
            value={confirmPassword} />
          <Button type='submit' >Sign Up</Button>
          </form>
        
        </div>
    )
}

export default SignUpForm